var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "navigation-tag-container" }, [
    _c(
      "div",
      {
        staticClass: "item-container",
        attrs: { id: "navigation-tag" },
        on: {
          wheel: function ($event) {
            $event.stopPropagation()
            return _vm.onwheel.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "transition-group",
          { attrs: { name: "list", tag: "div" } },
          _vm._l(_vm.navigationTags, function (item, index) {
            return _c(
              "div",
              {
                key: item.fullPath,
                staticClass: "tag-item",
                class: { active: _vm.currentTag === item },
                attrs: { tabindex: index },
                on: {
                  click: function ($event) {
                    return _vm.toggleTag(item)
                  },
                },
              },
              [
                _c("span"),
                _c("span", [_vm._v(_vm._s(_vm.$t(item.name)))]),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.deleteTag(index)
                      },
                    },
                  },
                  [_c("em", { staticClass: "el-icon-close" })]
                ),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "close-btn" },
      [
        _c(
          "el-dropdown",
          { attrs: { trigger: "click" }, on: { command: _vm.closeTag } },
          [
            _c("div", { staticStyle: { padding: "10px" } }, [
              _c("em", { staticClass: "el-icon-arrow-down" }),
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              _vm._l(_vm.dropdownItems, function (item) {
                return _c(
                  "el-dropdown-item",
                  {
                    key: item.type,
                    attrs: {
                      command: item.type,
                      disabled: !_vm.navigationTags.length,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")]
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }